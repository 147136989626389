import {formatModuleModeComponentName, imagize, richtextize, slugize, targetize} from '../../utils';
import {module, rawmodule} from "../../types";

export async function video({primary}: rawmodule): Promise<module> {
    return {
        component: formatModuleModeComponentName('Video', primary.mode, 'VideoPlayer'),
        data: {
            title: richtextize(primary?.title),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            image: imagize(primary?.image),
            placeholderImage: imagize(primary?.placeholder_image),
            url: targetize(primary?.url),
        },
        config: {
            richTextProps: ['title', 'sectionTitle'],
            targetProps: ['url'],
        },
    }
}

export default video
