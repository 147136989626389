import {formatModuleModeComponentName, richtextize, slugize} from '../../utils';
import {module, rawmodule} from "../../types";

export async function spacer({primary}: rawmodule): Promise<module> {
    return {
        component: formatModuleModeComponentName('Spacer', primary.mode, 'Vertical'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            size: primary?.size,
        },
        config: {
            richTextProps: ['sectionTitle'],
        },
    }
}

export default spacer