import {convertProductForLevel, formatModuleModeComponentName, richtextize, slugize} from '../../utils';
import {module as module_type, page as page_type, rawmodule} from '../../types';
import {getItemsFromDataSource} from '../../datasources';

export async function news_slider(module: rawmodule, page: page_type, ctx: any): Promise<module_type> {
    const {primary} = module;
    const items: any[] = await getItemsFromDataSource('audiobook_news', ctx, page);
    return {
        component: formatModuleModeComponentName('NewsSlider', 'default', 'Default'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            items: await Promise.all(
                (items as any[]).map(async ({product, ...item}) => ({
                    ...item,
                    product: await convertProductForLevel(product, 'item', ctx, page.lang),
                })),
            ),
        },
    };
}

export default news_slider;
