import {formatModuleModeComponentName, richtextize, slugize, productspecize} from '../../utils';
import {module, rawmodule} from '../../types';
import {product} from '../../ui';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function product_specs(module: rawmodule): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));

    const {primary} = module;

    return {
        component: formatModuleModeComponentName('ProductSpecs', primary.mode, 'Normal'),
        data: {
            title: richtextize(primary?.title),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            productSpecification: await productspecize(primary?.product_specification),
        },
        config: {
            richTextProps: ['title', 'sectionTitle'],
            productSpecificationProps: ['productSpecification'],
        },
    };
}

export default product_specs;
