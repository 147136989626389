import {formatModuleModeComponentName, richtextize, slugize} from '../../utils';
import {module, rawmodule} from "../../types";

export async function tags({primary}: rawmodule): Promise<module> {
    return {
        component: formatModuleModeComponentName('Tags', primary.mode, 'Standard'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
        },
        config: {
            richTextProps: ['sectionTitle'],
        },
    }
}

export default tags