import {
    formatModuleModeComponentName,
    richtextize,
    slugize,
    datetimeize,
    targetize,
} from '../../utils';
import {module, rawmodule} from '../../types';
import {product} from '../../ui';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function publication_heading(module: rawmodule): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));

    const {primary} = module;

    return {
        component: formatModuleModeComponentName('PublicationHeading', primary.mode, 'Standard'),
        data: {
            title: richtextize(primary?.title),
            overline: richtextize(primary?.overline),
            description: richtextize(primary?.description),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            date: datetimeize(primary?.date),
            url: targetize(primary?.url),
        },
        config: {
            richTextProps: ['title', 'overline', 'description', 'sectionTitle'],
            targetProps: ['url'],
        },
    };
}

export default publication_heading;
