import {formatModuleModeComponentName, imagize, richtextize, slugize, targetize} from '../../utils';
import {module, rawmodule} from '../../types';
import {product} from '../../ui';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function push(module: rawmodule): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));

    const {primary} = module;

    return {
        component: formatModuleModeComponentName('Push', primary.mode, 'PushLarge'),
        data: {
            title: richtextize(primary?.title),
            overline: richtextize(primary?.overline),
            label: richtextize(primary?.label),
            text: richtextize(primary?.text),
            buttonLabel: richtextize(primary?.button_label),
            button2Label: richtextize(primary?.button_2_label),
            button3Label: richtextize(primary?.button_3_label),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            image: imagize(primary?.image),
            image2: imagize(primary?.image_2),
            buttonTarget: targetize(primary?.button_target),
            button2Target: targetize(primary?.button_2_target),
            button3Target: targetize(primary?.button_3_target),
            buttonType: targetize(primary?.button_type),
            button2Type: targetize(primary?.button_2_type),
            button3Type: targetize(primary?.button_3_type),
        },
        config: {
            richTextProps: [
                'title',
                'overline',
                'label',
                'text',
                'buttonLabel',
                'button2Label',
                'button3Label',
                'sectionTitle',
            ],
            targetProps: ['buttonTarget', 'button2Target', 'button3Target'],
        },
    };
}

export default push;
