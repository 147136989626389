import {module, rawmodule} from '../../types';
import {formatModuleModeComponentName, richtextize, slugize} from '../../utils';

export async function heading_section(m: rawmodule): Promise<module> {
    const {primary} = m;
    return {
        component: formatModuleModeComponentName('HeadingSection', 'default', 'Default'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            universe: primary?.universe,
            position: primary?.position,
            variant: primary?.variant,
            overline: richtextize(primary?.overline),
            title: richtextize(primary?.title),
            text: richtextize(primary?.text),
        },
        config: {
            richTextProps: ['overline', 'title', 'text'],
        },
    };
}

export default heading_section;
