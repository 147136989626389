import {formatModuleModeComponentName, richtextize, slugize} from '../../utils';
import {module as module_type, rawmodule} from '../../types';
import {product as product_type} from '../../ui';

async function populate_from_product(product: product_type, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function extract_card(module: rawmodule): Promise<module_type> {
    // eslint-disable-next-line no-param-reassign
    module?.primary?.product && (module = await populate_from_product(module?.primary?.product, module));
    const {primary} = module;

    return {
        component: formatModuleModeComponentName('ExtractCard', 'default', 'Default'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
        },
        config: {
            richTextProps: [
                'sectionTitle',
            ],
        },
    };
}

export default extract_card;
