import {audiobook_news, build_home_recommendation, build_new_pack, page as page_type} from './types';
import {convertLangToCatalog, getTargetDevice, localeToCatalog} from './utils';

const addProduct = (pack: any, products: any, lang: string, trackingFromDataSource: string) => {
    pack.product = {
        ...products[`${convertLangToCatalog(lang)}-${pack.reference}`],
        trackingFromDataSource
    };
    // if (targetFlamOnlyAudiobook(pack?.product?.deviceTarget)) return null; // FLAM_MULTI_PRODUCT: hide FLAM audiobooks from new packs, news and recommendations
    return pack;
};

const getItemsFromAudiobookNews = async (context: any, page: page_type, trackingFromDataSource: string) => {
    const audiobookNews: audiobook_news[] = await context.context.cache.get('lunii_audiobook_news') || {};
    const cacheProducts = await context.context.cache.get('lunii_product_by_ids') || {};
    const filteredAudiobookNews: any[] = audiobookNews.filter((news: any) => news.catalog === localeToCatalog(page.lang));
    return filteredAudiobookNews.map((news: any) => addProduct(news, cacheProducts, page.lang, trackingFromDataSource)).filter((x: any) => !!x);
};

const getItemsFromNewPacks = async (context: any, page: page_type, trackingFromDataSource: string, target: 'fah' | 'flam' = 'fah') => {
    const newPacks: build_new_pack[] = await context.context.cache.get('lunii_new_packs') || {};
    const cacheProducts = await context.context.cache.get('lunii_product_by_ids') || {};
    const filteredNewPacks: any[] = newPacks.filter((pack: any) => pack.catalog === localeToCatalog(page.lang)).map((pack: build_new_pack) => addProduct(pack, cacheProducts, page.lang, trackingFromDataSource));
    return filteredNewPacks.filter((pack: any) => {
        const deviceTarget = getTargetDevice(pack.product?.deviceTarget || [], false);
        return !!pack && deviceTarget === target;
    }).sort((a: any, b: any) => b.product.creationDate - a.product.creationDate);
};

const getItemsFromRecommendations = async (context: any, page: page_type, trackingFromDataSource: string, target: 'fah' | 'flam' = 'fah') => {
    const recommendations: build_home_recommendation[] = await context.context.cache.get('lunii_home_recommendations') || {};
    const cacheProducts = await context.context.cache.get('lunii_product_by_ids') || {};
    const filteredRecommendations: any[] = recommendations.filter((pack: any) => pack.catalog === localeToCatalog(page.lang)).map((pack: build_home_recommendation) => addProduct(pack, cacheProducts, page.lang, trackingFromDataSource));
    return filteredRecommendations.filter((pack: any) => {
        const deviceTarget = getTargetDevice(pack.product?.deviceTarget || [], false);
        return !!pack && deviceTarget === target;
    }).sort((a: any, b: any) => b.product.creationDate - a.product.creationDate);
};

export const getItemsFromDataSource = async (datasource: string, context: any, page: page_type) => {
    switch (datasource) {
        case 'audiobook_news':
            return getItemsFromAudiobookNews(context, page, 'nos_histoires_luniistore_les_nouveautes');
        case 'new_packs':
            return getItemsFromNewPacks(context, page, 'nos_histoires_luniistore_les_petits_nouveaux', 'fah');
        case 'recommendations':
            return getItemsFromRecommendations(context, page, 'nos_histoires_luniistore_notre_selection', 'fah');
        case 'new_packs_flam':
            return getItemsFromNewPacks(context, page, 'nos_histoires_luniistore_les_petits_nouveaux', 'flam');
        case 'recommendations_flam':
            return getItemsFromRecommendations(context, page, 'nos_histoires_luniistore_notre_selection', 'flam');
        default:
            return [];
    }
};
