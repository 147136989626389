import {module, rawmodule} from '../../types';
import {formatModuleModeComponentName, imagize, richtextize, slugize, targetize} from '../../utils';

export async function heading_catalog(m: rawmodule): Promise<module> {
    const {primary} = m;
    return {
        component: formatModuleModeComponentName('HeadingCatalog', 'default', 'Default'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            title: richtextize(primary?.title),
            text: richtextize(primary?.text),
            icon: primary?.icon,
            universe: primary?.universe,
            image: imagize(primary?.image),
            buttonLabel: richtextize(primary?.button_label),
            buttonTarget: targetize(primary?.button_target),
            buttonIcon: primary?.button_icon,
            buttonIconPosition: primary?.button_icon_position,
        },
        config: {
            richTextProps: ['title', 'text', 'buttonLabel'],
            targetProps: ['buttonTarget'],
        },
    };
}

export default heading_catalog;
