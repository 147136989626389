import {richText2react} from "../../utils";

export function productSpecification(v: any|undefined, p: {[key: string]: any}, k: string) {
    if (!v || !v.items || !v.items.length) return;
    p[k] = {
        items: v.items.map((section: any) => ({
            title: richText2react(section.title),
            excludedLocales: section.excludedLocales,
            items: (section.items || []).map((item: any) => ({
                excludedLocales: item.excludedLocales,
                label: richText2react(item.label),
                content: richText2react(item.content),
            })),
        })),
    };
}

export default productSpecification
