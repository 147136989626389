import {formatModuleModeComponentName, richtextize, slugize} from '../../utils';
import {module, rawmodule} from '../../types';
import {product} from '../../ui';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function paragraph(module: rawmodule): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));

    const {primary} = module;

    return {
        component: formatModuleModeComponentName('Paragraph', primary.mode, 'Standard'),
        data: {
            title: richtextize(primary?.title),
            subtitle: richtextize(primary?.subtitle),
            text: richtextize(primary?.text),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
        },
        config: {
            richTextProps: ['title', 'subtitle', 'text', 'sectionTitle'],
        },
    };
}

export default paragraph;
