import {module, rawmodule, page} from '../../types';
import {formatModuleModeComponentName, imagize, richtextize, slugize} from '../../utils';

export async function e_commerce(module: rawmodule, page: page): Promise<module> {
    const {primary, items = []} = module;
    return {
        component: formatModuleModeComponentName('ECommerce', primary.mode, 'Hardware'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            text: richtextize(primary?.text),
            itemsTitle: richtextize(primary?.items_title),
            items: await Promise.all((items as any[])
                .filter(item => (item.excluded_locales || '').split(',').indexOf(page.lang?.slice(-2)) === -1)
                .map(async item => ({
                image: imagize(item.image),
                image2: imagize(item.image_2),
            }))),
        },
        config: {
            richTextProps: ['text', 'itemsTitle'],
        },
    };
}

export default e_commerce;
