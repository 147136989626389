import {module, rawmodule} from "../../types";

export async function unknown({moduleType, primary}: rawmodule): Promise<module> {
    return {
        component: ['UnknownModeUnknownModule'],
        data: {
            module: moduleType,
            mode: primary?.mode,
        }
    }
}

export default unknown
