import {formatModuleModeComponentName, imagize, menuize, richtextize, slugize} from '../../utils';
import {module, rawmodule} from '../../types';

export async function footer({primary}: rawmodule): Promise<module> {
    return {
        component: formatModuleModeComponentName('Footer', primary.mode, 'Page'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            menu: menuize(primary?.menu),
            shareMenu: menuize(primary?.share_menu),
            sectionId: slugize(primary?.section_id),
            image: imagize(primary?.image),
        },
        config: {
            richTextProps: ['sectionTitle'],
            menuProps: ['menu', 'shareMenu'],
        },
    };
}

export default footer;
