import {module, page, rawmodule} from '../../types';
import {
    formatModuleModeComponentName,
    imagize,
    richText2react,
    richtextize,
    targetize,
} from '../../utils';
import {PageMenuItemInterface} from '../../ui';

export async function page_menu(module: rawmodule, page: page): Promise<module | undefined> {
    const currentPageTarget = `/${page.lang}/${page.uid}/`;
    const menuItemsFromPrismic = module?.primary?.menu?.document?.data?.body;
    const menuData = module?.primary?.menu?.document?.data;
    if (!menuItemsFromPrismic || !menuItemsFromPrismic.length) return;

    const items: PageMenuItemInterface[] = [];
    const {primary} = module;
    let defaultItem;

    menuItemsFromPrismic.map(
        ({
            id,
            primary: {
                label,
                target: {url},
            },
        }: {
            id: string;
            primary: {label: string; target: {url: string}};
        }) => {
            const link = targetize(url);
            const item = {id, label: richText2react(richtextize(label)), link};
            items.push(item);
            if (currentPageTarget === link) defaultItem = item;
        },
    );

    return {
        component: formatModuleModeComponentName('PageMenu', primary.mode, 'Default'),
        data: {
            items,
            title: richtextize(primary?.title),
            defaultItem,
            logo: {
                image: imagize(menuData?.menu_image),
            },
        },
        config: {
            richTextProps: ['title'],
        },
    };
}

export default page_menu;
