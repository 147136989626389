import {formatModuleModeComponentName, richtextize, slugize, configize} from '../../utils';
import {module, rawmodule} from '../../types';
import {product} from '../../ui';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function custom(module: rawmodule): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));

    const {primary} = module;

    return configize(
        {
            component: formatModuleModeComponentName('Custom', primary.mode, 'Unknown'),
            data: {
                sectionTitle: richtextize(primary?.section_title),
                sectionId: slugize(primary?.section_id),
            },
            config: {
                richTextProps: ['sectionTitle'],
            },
            requires: {
                catalog: ['catalog', 'catalog flam'].indexOf(module?.primary?.mode) !== -1, // trigger the population of the catalog context
                giftCards: module?.primary?.mode === 'gift cards', // trigger the population of the gift card context
            },
        },
        primary?.config,
        module,
    );
}

export default custom;
