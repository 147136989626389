import {module, rawmodule} from '../../types';
import {formatModuleModeComponentName, richtextize, slugize, targetize} from '../../utils';

export async function simplesat(module: rawmodule): Promise<module> {
    const {primary} = module;
    return {
        component: formatModuleModeComponentName('Simplesat', primary.mode, 'Default'),
        data: {
            dataWidgetId: primary?.data_widget_id,
            backgroundColor: primary?.background_color,
            title: richtextize(primary?.title),
            text: richtextize(primary?.text),
            buttonLabel: richtextize(primary?.button_label),
            buttonTarget: targetize(primary?.button_target),
            buttonType: targetize(primary?.button_type),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
        },
        config: {
            richTextProps: ['title', 'text', 'buttonLabel', 'sectionTitle'],
            targetProps: ['buttonTarget'],
        },
    };
}

export default simplesat;
