import {formatModuleModeComponentName, richtextize} from '../../utils';
import {module, rawmodule} from '../../types';

export async function dynamic_items(module: rawmodule): Promise<module> {
    const {primary} = module;

    return {
        component: formatModuleModeComponentName('DynamicItems', primary.mode, 'CarrousselCardSmall'),
        data: {
            title: richtextize(primary?.title),
            text: richtextize(primary?.text),
            dataSource: primary?.data_source,
        },
        config: {
            richTextProps: ['title', 'text'],
        },
        itemConfig: {
            richTextProps: ['title', 'text'],
        },
    };
}

export default dynamic_items;
