import {formatModuleModeComponentName, slugize, richtextize, targetize, imagize, convertProductForLevel} from '../../utils';
import {module as module_type, rawmodule, page as page_type} from '../../types';
import {getItemsFromDataSource} from '../../datasources';
import {pageModelItemsTracking} from '../../../configs/site';

async function populateFromSource(source: string, module: rawmodule, ctx: any, page: page_type): Promise<rawmodule> {
    const itemsFromDataSource = await getItemsFromDataSource(source, ctx, page);
    return {
        ...module,
        ...(!!itemsFromDataSource && {items: itemsFromDataSource}),
    }
}

export async function product_list_section(module: rawmodule, page: page_type, ctx: any): Promise<module_type> {
    const {primary, items = []} = module?.primary?.data_source ? await populateFromSource(module?.primary?.data_source, module, ctx, page) : module;
    const model: string = page.pageModel || '';
    const itemsAreTracked: boolean = pageModelItemsTracking.includes(model);
    return {
        component: formatModuleModeComponentName('ProductListSection', primary.mode, 'Default'),
        data: {
            itemsAreTracked,
            title: richtextize(primary?.title),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            items: await Promise.all(
                (items as any[])
                    .filter(item => (item.excluded_locales || '').split(',').indexOf(page.lang?.slice(-2)) === -1)
                    .map(async (item) => {
                    const product = await convertProductForLevel(item.product, 'item', ctx, page.lang);
                    return {
                        title: richtextize(item.title),
                        description: richtextize(item.description),
                        target: targetize(item.target),
                        buttonLabel: richtextize(item.button_label),
                        buttonType: item.button_type,
                        buttonTarget: targetize(item.button_target),
                        image: imagize(item.image),
                        tagIcon: item.tag_icon,
                        tagLabel: item.tag_label,
                        tagTheme: item.tag_theme,
                        product,
                    };
                }),
            ),
        },
        config: {
            richTextProps: ['title', 'sectionTitle'],
        },
        itemConfig: {
            richTextProps: ['title', 'description', 'buttonLabel', 'sectionTitle'],
            targetProps: ['target', 'buttonTarget'],
        },
    };
}

export default product_list_section;
