import {formatModuleModeComponentName, imagize, richtextize, slugize} from '../../utils';
import {module, rawmodule} from '../../types';
import {product} from '../../ui';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function image(module: rawmodule): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));

    const {primary} = module;

    return {
        component: formatModuleModeComponentName('Image', primary.mode, 'Medium'),
        data: {
            label: richtextize(primary?.label),
            sectionTitle: richtextize(primary?.section_title),
            image: imagize(primary?.image),
            sectionId: slugize(primary?.section_id),
        },
        config: {
            richTextProps: ['label', 'sectionTitle'],
        },
    };
}

export default image;
