import {module, page, rawmodule} from '../../types';
import {formatModuleModeComponentName} from '../../utils';

export async function catalog_search(module: rawmodule, page: page): Promise<module | undefined> {
    const {primary} = module;

    return {
        component: formatModuleModeComponentName('CatalogSearch', primary.mode, 'Default'),
        data: { },
        config: { },
    };
}

export default catalog_search;
