import {module, rawmodule, page as page_type} from '../../types';
import {formatModuleModeComponentName, imagize, richtextize, slugize, targetize} from '../../utils';

export async function product_overview_section_spec(m: rawmodule, page: page_type): Promise<module> {
    const {primary, items = []} = m;
    return {
        component: formatModuleModeComponentName('ProductOverviewSectionSpec', primary.mode, 'Hardware'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            title: richtextize(primary?.title),
            text: richtextize(primary?.text),
            itemsTitle: richtextize(primary?.items_title),
            tagIcon: primary?.tag_icon,
            tagLabel: primary?.tag_label,
            tagTheme: primary?.tag_theme,
            universe: primary?.universe,
            image1: imagize(primary?.image_1),
            image1Small: imagize(primary?.image_1_small),
            image2: imagize(primary?.image_2),
            image2Small: imagize(primary?.image_2_small),
            image3: imagize(primary?.image_3),
            image3Small: imagize(primary?.image_3_small),
            image4: imagize(primary?.image_4),
            image4Small: imagize(primary?.image_4_small),
            alertLinkLabel: richtextize(primary?.alert_link_label),
            alertLinkIcon: primary?.alert_link_icon,
            alertLinkIconPosition: primary?.alert_link_icon_position,
            alertLinkUrl: targetize(primary?.alert_link_url),
            alertLink2Label: richtextize(primary?.alert_link_2_label),
            alertLink2Icon: primary?.alert_link_2_icon,
            alertLink2IconPosition: primary?.alert_link_2_icon_position,
            alertLink2Url: targetize(primary?.alert_link_2_url),
            alertButtonLabel: richtextize(primary?.alert_button_label),
            alertButtonIcon: primary?.alert_button_icon,
            alertButtonIconPosition: primary?.alert_button_icon_position,
            alertButtonUrl: targetize(primary?.alert_button_url),
            alertTitle: richtextize(primary?.alert_title),
            alertDescription: richtextize(primary?.alert_description),
            alertTheme: primary?.alert_theme,
            alertClosable: primary.alert_closable,
            alertIcon: primary?.alert_icon,
            alertImage: imagize(primary?.alert_image),
            items: await Promise.all((items as any[])
                .filter(item => (item.excluded_locales || '').split(',').indexOf(page.lang?.slice(-2)) === -1)
                .map(async item => ({
                title: richtextize(item.title),
                text: richtextize(item.text),
                icon: item.icon
            }))),
        },
        config: {
            richTextProps: ['title', 'text', 'itemsTitle', 'alertButtonLabel', 'alertLinkLabel', 'alertLink2Label', 'alertTitle', 'alertDescription'],
            targetProps: ['alertButtonUrl', 'alertLinkUrl', 'alertLink2Url'],
        },
        itemConfig: {
            richTextProps: ['title', 'text'],
        },
    };
}

export default product_overview_section_spec;
