import {formatModuleModeComponentName, slugize, richtextize, targetize} from '../../utils';
import {module, rawmodule} from "../../types";

export async function alert_banner({primary}: rawmodule): Promise<module> {
    return {
        component: formatModuleModeComponentName('AlertBanner', primary.mode, 'Alert'),
        data: {
            text: richtextize(primary?.text),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
            target: targetize(primary?.target),
            closable: primary?.closable,
        },
        config: {
            richTextProps: ['text', 'sectionTitle'],
            targetProps: ['target'],
        },
    };
}

export default alert_banner
