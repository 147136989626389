import {formatModuleModeComponentName, richtextize, slugize} from '../../utils';
import {module, rawmodule} from '../../types';

export async function share({primary}: rawmodule): Promise<module> {
    return {
        component: formatModuleModeComponentName('Share', primary.mode, 'Standard'),
        data: {
            title: richtextize(primary?.title),
            sectionTitle: richtextize(primary?.section_title),
            sectionId: slugize(primary?.section_id),
        },
        config: {
            richTextProps: ['title', 'sectionTitle'],
        },
    };
}

export default share;
