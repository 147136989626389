import {formatModuleModeComponentName, slugize, richtextize, menuize, imagize} from '../../utils';
import {module, rawmodule} from '../../types';
import {product} from '../../ui';

async function populate_from_product(product: product, module: rawmodule): Promise<rawmodule> {
    // @todo inject data from source into the raw module, as if they were populated manually
    return module;
}

export async function app_bar(module: rawmodule): Promise<module> {
    module?.primary?.product &&
        (module = await populate_from_product(module?.primary?.product, module));

    const {primary} = module;
    const menuData = primary?.menu?.document?.data;

    return {
        component: formatModuleModeComponentName('AppBar', primary.mode, 'Page'),
        data: {
            sectionTitle: richtextize(primary?.section_title),
            menu: menuize(primary?.menu),
            menuImage: imagize(menuData?.menu_image),
            sectionId: slugize(primary?.section_id),
            image: imagize(primary?.image),
            raw: primary,
        },
        config: {
            richTextProps: ['sectionTitle'],
            menuProps: ['menu'],
        },
    };
}

export default app_bar;
