import {richText2react} from '../../utils';
import {menuitem} from '../../ui';

export function menu(v: any | undefined, p: {[key: string]: any}, k: string) {
    p[k] = (v || []).map((item: any) => ({
        ...item,
        label: richText2react(item.label),
        items: (item.items || []).map((subitem: any) => ({
            ...subitem,
            label: richText2react(subitem.label),
        })),
    }));
}

export function menuClean(v: any | undefined): menuitem[] {
    return (v || []).map((item: any) => ({
        ...item,
        label: richText2react(item.label),
        items: (item.items || []).map((subitem: any) => ({
            ...subitem,
            label: richText2react(subitem.label),
        })),
    }));
}

export default menu;
